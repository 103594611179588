<template>
  <div class="page-content">
    <van-cell-group title="跟踪信息">
      <van-cell :value="listData.realname" title="客户姓名" />
      <van-cell :value="listData.mobile" title="手机电话" />
      <!-- <van-cell title="销售顾问" :value="$store.state.userList[dataList.seller_uid][0]" /> -->
      <van-cell :value="listData.is_vip == '1' ? '是' : '否'" title="重点客户" />
      <van-cell :value="listData.expect_buy_date" title="预计购车日期" />
      <van-cell :value="'￥ '+listData.media_amount" title="车型媒体价" />
      <van-cell :value="'￥ '+listData.purchase_tax_amount" title="购置税" />
      <van-cell :value="listData.contend_brand" title="竞争品牌" />
      <van-cell :value="listData.contend_model" title="竞争车型" />
      <van-cell :value="'￥ '+listData.discount_amount" title="优惠折扣" />
      <van-cell :value="'￥ '+listData.buy_parts_amount" title="购买精品金额" />
      <van-cell :value="getCfgText(listData.client_level)" title="客户级别" />
      <van-cell :value="listData.buy_parts_items" title="购买精品明细" />
      <van-cell :value="listData.next_trace_date" title="下次回访日期" />
      <van-cell title="跟踪文件">
        <template #label>
          <div class="file-list">
            <div :key="index" class="file-item" v-for="(item, index) in listData.audio_file_list">
              <van-image
                :src="item.url"
                @click="imagePreview([item.url])"
                fit="contain"
                height="80"
                v-if="getFileType(item.name) > -1"
                width="80"
              />
              <div @click="playAudio(item.url)" class="audio-box">
                <van-icon :name="audioIcon" size="20" />
              </div>
              <!-- <van-button @click="playAudio(item.url)" icon="star-o" type="primary" v-else /> -->
            </div>
          </div>
        </template>

        <!-- <a
          :href="item.url"
          :key="index"
          class="file-list"
          target="_blank"
          v-for="(item, index) in listData.audio_file_list"
        >{{item.name}}</a>-->
      </van-cell>

      <!-- <van-cell :value="$store.state.userList[dataList.old_seller_uid][0]" title="原销售顾问" /> -->
      <van-cell :value="'￥ '+listData.used_car_amount" title="二手车折价" />
      <van-cell :value="listData.donate_parts_items" title="赠送精品明细" />
      <van-cell :value="listData.expect_pay_date" title="邀约到店日期" />
      <van-cell :value="'￥ '+listData.insurance_tax_amount" title="预估保费" />
      <van-cell :value="getCfgText(listData.trace_type)" title="跟踪类型" />
      <van-cell :value="'￥ '+listData.service_tax_amount" title="综合服务费" />
      <van-cell :value="getCfgText(listData.trace_status)" title="促进结果" />
      <van-cell :value="'￥ '+listData.net_amount" title="车辆净价" />
      <van-cell :value="'￥ '+listData.tax_amount" title="预计总费用" />
      <van-cell :value="getCfgText(listData.credit_type)" title="贷款方式" />
      <van-cell :value="listData.trace_memo" title="跟踪描述" />
    </van-cell-group>
    <van-cell-group title="贷款明细">
      <van-cell
        :key="key"
        :title="credit_detail[key]"
        :value="item"
        v-for="(item, key) in JSON.parse(listData.credit_detail)"
      />
    </van-cell-group>
    <van-cell-group title="保险明细">
      <div :key="key" v-for="(item, key) in JSON.parse(listData.insurance_detail)">
        <van-cell :title="insurance_detail[key]" :value="item" v-if="key == 'threePayment'" />
        <van-cell :title="insurance_detail[key]" :value="item == 1 ? '有' : '无'" v-else />
      </div>
    </van-cell-group>
    <van-cell-group title="其它明细">
      <van-cell :value="listData.book_date" title="订车日期" />
      <van-cell :value="listData.deliver_date" title="交车日期" />
      <van-cell :value="listData.unbook_date" title="退订日期" />
      <van-cell :value="listData.lose_date" title="失控日期" />
      <van-cell :value="listData.fall_brand" title="战败品牌" />
      <van-cell :value="listData.fall_model" title="战败车型" />
      <van-cell :value="listData.fall_memo" title="战败说明" />
    </van-cell-group>
  </div>
</template>
<script>
import { ImagePreview } from 'vant'
export default {
  data() {
    return {
      trace_id: 0,
      credit_detail: {
        loan: '贷款金额',
        downPayment: '首付金额',
        monthlyPayment: '月供金额',
        interest: '利息金额',
        // cost1: '银行审核费',
        // cost2: '货款审核费',
        // cost3: '车辆抵押费'
      },
      insurance_detail: {
        three: '三责',
        threePayment: '三责金额',
        insurance1: '交强险',
        insurance2: '车损险',
        // insurance3: '不计免赔',
        insurance4: '划痕险',
        insurance5: '盗抢险',
        // insurance6: '玻璃险',
        insurance7: '意外伤害险',
        insurance8: '座位险',
        insurance9: '随车行李险',
        // insurance10: '自然险'
      },
      listData: {},
      imgType: ['png', 'gif', 'jpeg', 'jpg'],
      audio: null,
      audioSrc: '',
      audioIcon: 'play'
    }
  },
  mounted() {},
  methods: {
    //获取文件类型
    getFileType(name) {
      let tmpArr = name.split('.')
      let str = tmpArr[tmpArr.length - 1]
      return this.imgType.indexOf(str)
    },

    show(id) {
      this.trace_id = parseInt(id) 
      this.view()
    },
    imagePreview(imgArr) {
      ImagePreview(imgArr)
    },
    playAudio(audioSrc) {
      let _this = this
      if (audioSrc != this.audioSrc) {
        this.audioSrc = audioSrc
        this.audio = new Audio(_this.audioSrc)
        this.audio.onended = () => {
          this.audioIcon = 'play'
        }
      }

      // if(this.audio){
      //   this.audio.pause()
      // }

      if (this.audio.paused) {
        this.audio.play()
        this.audioIcon = 'pause'
        
      } else {
        this.audio.pause()
        this.audioIcon = 'play'
      }
    },
    //返回自定义片段的键值
    getFieldKey(str) {
      let id = str.replace(/field_/g, '')
      return this.cfgfield[id]
    },
    getCfgText(key) {
      if (key == '') {
        return '-'
      }

      if (this.$store.state.cfgData[key] != undefined) {
        return this.$store.state.cfgData[key][0]
      } else {
        return '-'
      }
    },
    view() {
      this.$axios
        .post('/trace/detail', {
          trace_id: this.trace_id
        })
        .then(res => {
          console.log(res)
          this.listData = res.data.data
          // this.$emit('checkStatus', res.data.data.check_status)
        })
    }
  }
}
</script>
<style lang="less" scoped>
.file-list {
  display: flex;
  .file-item {
    background-color: #f7f8fa;
    width: 80px;
    height: 80px;
    overflow: hidden;
    margin-right: 5px;
  }
  .audio-box {
    width: 80px;
    height: 80px;
    color: #969799;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>