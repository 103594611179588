<template>
  <div class="main">
    <van-list
      :finished="finished"
      @load="onLoad"
      class="main-body"
      finished-text="没有更多了"
      v-model="loading"
    >
      <van-swipe-cell :key="index" v-for="(item, index) in listData">
        <van-cell @click="showDetail(item.trace_id)">
          <div class="items">
            <div class="item">
              <div class="label">客户姓名:</div>
              <div class="val">{{item.realname}}</div>
            </div>
            <div class="item">
              <div class="label">联系手机:</div>
              <div class="val">{{item.mobile}}</div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">跟踪日期:</div>
              <div class="val">{{item.trace_date}}</div>
            </div>
            <div class="item">
              <div class="label">客户级别:</div>
              <div class="val">{{getCfgText(item.client_level)}}</div>
            </div>
          </div>
        </van-cell>

        <template #right>
          <van-button @click="showDetail(item.trace_id)" square text="查看" type="info" />
        </template>
      </van-swipe-cell>
    </van-list>

    <van-popup position="bottom" v-model="showSearch">
      <van-cell-group title="搜索条件">
        <van-field clearable label="客户姓名" placeholder="请输入" v-model="seachKey.realname" />
        <van-field clearable label="手机号" placeholder="请输入" v-model="seachKey.mobile" />

        <van-field
          @click="showCalendar = true"
          isLink
          label="下次回访日期"
          placeholder="请选择"
          v-model="next_trace_date"
        />
        <van-calendar @confirm="onConfirmCalendar" type="range" v-model="showCalendar" />

        <field-select
          :columns="cfgListArr('channel')"
          :label="'线索渠道'"
          isLink
          placeholder="请选择"
          v-model="seachKey.clue_source"
        ></field-select>
        <field-select
          :columns="cfgListArr('buyTimes')"
          :label="'购车类型'"
          isLink
          placeholder="请选择"
          v-model="seachKey.buy_type"
        ></field-select>
        <field-select
          :columns="cfgListArr('influence')"
          :label="'影响因素'"
          isLink
          placeholder="请选择"
          v-model="seachKey.affected_item"
        ></field-select>
        <field-select
          :columns="cfgListArr('demandCarType')"
          :label="'需求车型'"
          isLink
          placeholder="请选择"
          v-model="seachKey.target_model"
        ></field-select>
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button @click="search" block round type="info">搜 索</van-button>
      </div>
    </van-popup>

    <van-popup
      :style="{ width: '100%', height: '100%' }"
      @opened="openDetail"
      position="right"
      v-model="showClient"
    >
      <trace-detail @checkStatus="checkStatus" ref="trace"></trace-detail>
    </van-popup>

    <!-- 检核信息 -->
    <van-popup
      class="round-popup"
      position="right"
      style="height: 100%; width: 100%;"
      v-model="popupVisible"
    >
      <van-nav-bar @click-left="closePopup" left-arrow title="检核">
        <!-- <template #right>
          <van-icon name="search" size="18" />
        </template>-->
      </van-nav-bar>
      <div class="cell-group">
        <van-field
          autosize
          label="检核意见"
          placeholder="请输入"
          rows="2"
          type="textarea"
          v-model="form.check_memo"
        />
      </div>
      <div class="btn">
        <van-button block round type="info" @click="onSubmit">提交</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { sellList, cfgList } from '@/unit/unit.js'
import FieldSelect from '@/components/FieldSelect.vue'
import TraceDetail from '@/components/TraceDetail.vue'
// import FieldDate from '@/components/FieldDate.vue'
export default {
  components: {
    FieldSelect,
    TraceDetail
  },
  data() {
    return {
      showClient: false,
      trace_id: false,
      showSearch: false,
      showCalendar: false,
      popupVisible: false,

      isCheck: 2, //是否已检核

      loading: false,
      finished: false,
      total: 0,
      listData: [],
      next_trace_date: '',
      seachKey: {
        seller_uid: null,
        client_id: parseInt(this.$route.query.client_id),
        realname: '',
        mobile: '',
        trace_status: null,
        next_trace_date_begin: null,
        next_trace_date_end: null,

        next_trace_date_begin_end: null,

        page: 1,
        count: 15
      },
      form: {
        trace_id: 0,
        check_memo: ''
      }
    }
  },
  mounted() {
    // this.getListData()
  },
  methods: {
    //检核popup 开始
    showPopup() {
      if (this.isCheck == 1) {
        this.$toast.fail('跟踪已检核')
        return false
      }
      this.popupVisible = true
    },
    closePopup() {
      this.form.check_memo = ''
      this.popupVisible = false
    },

    onSubmit(){
      this.$axios.post('/trace/check', this.form).then(res => {
        if(res.data.code == 200){
          this.$notify({ type: 'success', message: res.data.msg })
          this.isCheck = 1
          this.closePopup()
        }else{
          this.$notify({ type: 'danger', message: res.data.msg })
          
        }
      })
    },

    //检核popup 结束
    checkStatus(val) {
      this.isCheck = val
    },

    showDetail(id) {
      this.form.trace_id = id
      // this.showClient = true
      this.$router.push({name: 'customer-tracedetail', query: {trace_id: id}})

    },
    openDetail() {
      this.$refs.trace.show(this.form.trace_id)
    },
    getCfgText(key) {
      if (key == '') {
        return '-'
      }

      if (this.$store.state.cfgData[key] != undefined) {
        return this.$store.state.cfgData[key][0]
      } else {
        return '-'
      }
    },
    search() {
      this.listData = []
      this.seachKey.page = 1
      this.showSearch = false
      this.onLoad()
    },
    sellArr() {
      return sellList()
    },
    cfgListArr(type) {
      return cfgList(type)
    },

    onConfirmCalendar(date) {
      const [start, end] = date
      this.showCalendar = false
      this.next_trace_date = `${this.formatDate(start)} / ${this.formatDate(
        end
      )}`
      this.seachKey.next_trace_date_begin = this.formatDate(start)
      this.seachKey.next_trace_date_end = this.formatDate(end)
    },

    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },

    onLoad() {
      setTimeout(() => {
        this.getListData()
      }, 500)
    },

    getListData() {
      this.$axios.post('/trace/lists', this.seachKey).then(res => {
        if (res.data.code == 200) {
          res.data.data.list.map(item => {
            this.listData.push(item)
          })

          this.total = res.data.data.total
          this.loading = false

          // 数据全部加载完成
          if (this.listData.length >= res.data.data.total) {
            this.finished = true
          }

          this.seachKey.page++
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.main-body {
  padding: 5px 0;
  /deep/ .van-swipe-cell__right .van-button {
    height: 100%;
  }
  /deep/ .van-swipe-cell {
    padding: 5px 0;
  }

  .items {
    display: flex;
    align-items: center;
    .item {
      flex: 1;
      display: flex;
      .label {
        padding: 3px 8px 3px 0;
        color: #606266;
      }
      .val {
        padding: 3px 0;
        font-weight: 500;
      }
    }
  }
}
</style>